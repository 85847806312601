//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import blok from '~/mixins/blok'
export default {
  mixins: [blok],
  computed: {
    image () {
      return this.$sb.parseImage(this.blok.image)
    },

    contentHtml () {
      return this.$storyapi.richTextResolver.render(this.blok.content)
    },

    containerClass () {
      const cls = ['tw-max-w-6xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8']

      if (this.blok.flip) {
        cls.push('tw-flex-col tw-space-y-8 md:tw-space-y-0 md:tw-flex-row-reverse md:tw-space-x-reverse lg:tw-space-x-reverse')
      } else {
        cls.push('tw-flex-col tw-space-y-8 md:tw-space-y-0 md:tw-flex-row')
      }

      return cls
    },

    sectionClass () {
      const classes = []

      switch (this.blok.background) {
        case 'cream':
          classes.push('tw-bg-cream-50')
          break
        default:
          break
      }

      if (this.blok.vertical_margin_size !== 'small') {
        classes.push('sm:tw-py-32')
      }

      return classes
    },

    titleClass () {
      if (this.blok.title_size === 'small') {
        return 'sm:tw-text-3xl'
      }
      return 'sm:tw-text-4xl'
    },
  }
}
