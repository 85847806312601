import { render, staticRenderFns } from "./BlokSectionJumbotron.vue?vue&type=template&id=6a64ace4&"
import script from "./BlokSectionJumbotron.vue?vue&type=script&lang=js&"
export * from "./BlokSectionJumbotron.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomLinkV2: require('/builds/docue/website/components/CustomLinkV2.vue').default})
